import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  constructor(private _Activatedroute: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer,) { }
  id: any
  article: any
  apiurl = environment.apiUrlPublic
  body: any
  video_url: any
  video_file: any
  cover: any
  displayCustom: boolean = false;
  moreArticles = []

  activeIndex: number = 0;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  async ngOnInit() {

    this._Activatedroute.params.subscribe(async params => {
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;

      let page: any = await this.api.getPage(this.id).toPromise()

      this.article = page.data

      var date = new Date(this.article.attributes.published);
      var n = date.toLocaleDateString();
      var time = date.toLocaleTimeString();
      this.article.attributes.published = n
      if (this.article.attributes.body) {
        this.body = this.sanitizer.bypassSecurityTrustHtml(this.article.attributes.body);
      } else {
        this.body = ''
      }
      if (this.article.attributes.cover.data) {
        this.cover = `${this.apiurl}${this.article.attributes.cover.data.attributes.url}`
      }

      if (this.article.attributes.files.data) {
        this.article.attributes.files.data.forEach((element: any) => {
          element.attributes.url = `${this.apiurl}${element.attributes.url}`
        });
      }

      if (this.article.attributes.photos.data) {
        this.article.attributes.photos.data.forEach((element: any) => {
          element.attributes.url = `${this.apiurl}${element.attributes.url}`
        });
      }
      console.log(this.article.attributes.photos.data)
      let catId = this.article.attributes.categories?.data[0]?.id
      this.getarticles(catId)

    });


  }

  async getarticles(id) {

    let moreArticles: any = await this.api.getCategory2(id, 4, 'el', 1).toPromise()
    moreArticles.data.forEach((element: any) => {

      var date2 = new Date(element.attributes.published);
      var n = date2.toLocaleDateString('el-GR', { timeZone: 'Europe/Athens' })
      element.attributes.published = n

      if (element.attributes.cover.data) {
        element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`
      } else {
        element.cover = `/assets/logo.png`
      }


    });
    this.moreArticles = moreArticles.data
    console.log(moreArticles)


  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

}

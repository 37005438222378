import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {

  constructor(private snackBar: MatSnackBar,private api: ApiService,) { }
  token: string | undefined;
  recaptchaValid: boolean; //whether or not the captcha is valid

  ngOnInit(): void {
  }

  public async send(form: NgForm) {
    /*
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    console.debug(`Token [${this.token}] generated`);
    */
    console.log(form.valid)
    console.log(this.recaptchaValid)
    if (form.valid) {
      //create the object that will be sent to the api
      type objType = {
        to: string, //email receiver
        from: string, //email sender
        subject: string, //the subject of the email
        text: string, //the message
        html: string //the html template of the email
      }

      //this will be the message that will be sent with the email
      const htmlTemplate: string = 
      `
        <head>Μήνυμα για το Επιμελητήριο Λέσβου</head>
        <body>
          <h2>Στοιχεία επικοινωνίας αποστολέα:</h2>
          <p>Ονοματεπώνυμο: ${form.value.name}</p>
          <p>Τηλέφωνο: ${form.value.phone}</p>
          <p>Email: ${form.value.email}</p>
          <div>
            <p>${form.value.message}<p>
          </div>
        </body>
      `

      //defining the object that will be sent to the api
      const myObject: objType = {
        to: "bountakidou.k@sga.gr",
        from: "no-reply@lesvos-chamber.com", 
        subject: "New email",
        text: "Μήνυμα από τη φόρμα επικοινωνίας επιμελητηρίου Λέσβου",
        html: htmlTemplate,
      } 
      const response: any = await this.api.postEmail(myObject).toPromise();

      //snackBar opening for successfull email sending
      this.snackBar.open(
        "Το email στάλθηκε επιτυχώς!", 
        "Το κατάλαβα!", 
        { 
          duration: 5000, 
          panelClass: ["snackBar", "snackBarSuccess"]
        }
      )

    
    } else {
      //snackBar opening for failed email sending
      this.snackBar.open(
        "Το email δεν στάλθηκε! Βεβαιωθείτε ότι έχετε αποδεχτεί το captcha.", 
        "Το κατάλαβα!", 
        {
          duration: 5000,
          panelClass: ["snackBar", "snackBarFailure"]
        }
      )
    }
  }

}

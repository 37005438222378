import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private api: ApiService,private translate: TranslateService,) { }
  navbarDropdown = `EL`
  iconUrl = `../../../assets/greece.png`

  navItems: any[] = []
  boxItems: any = [];
  latestNews: any[] = []
  apiurl = environment.apiUrlPublic

  async ngOnInit() {

    let language = this.translate.currentLang
    if (this.translate.currentLang == undefined) {
      language = 'el'
    }

    let latestNews: any =  await this.api.getCategory2('4',4, language, 1).toPromise()
    this.latestNews = latestNews.data
    for (let index = 0; index < this.latestNews.length; index++) {
      const element = this.latestNews[index];
      var date = new Date(element.attributes.published);
      var n = date.toLocaleDateString();
      element.attributes.published = n
      
    }
    await this.fetchMenus(language);

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {

      await this.fetchMenus(event.lang);
      let latestNews: any =  await this.api.getCategory2('4',4, event.lang, 1).toPromise()
      this.latestNews = latestNews.data

    })
    
  }

  //the function used to fetch the menu items. Is used at the ngOnInit, and at the onLangChange event.
  async fetchMenus(language: string) {
    this.navItems = []
    let menus: any = await this.api.getMenus(language).toPromise();
   
    menus.data.forEach((element: { id: number, children: any[] }) => {

      element.children = [];
      menus.data.forEach((obj: { attributes: { parent: { data: { id: number } } } }) => {
        if (obj.attributes.parent.data && obj.attributes.parent.data.id == element.id) {
          element.children = [...element.children, obj]
        }
      });
      element.children.sort((a, b) => (a.attributes.rank > b.attributes.rank) ? 1 : ((b.attributes.rank > a.attributes.rank) ? -1 : 0))

    });

    type el = {
      attributes: { rank: number }
    }
    menus.data.sort((a: el, b: el) => (a.attributes.rank > b.attributes.rank) ? 1 : ((b.attributes.rank > a.attributes.rank) ? -1 : 0))


    menus.data.forEach((element: { attributes: { parent: { data: any } } }) => {
      if (!element.attributes.parent.data) {
        this.navItems = [...this.navItems, element]
      }
    });
  }

  // Method to change the language.
  async switchLanguage(language: string, event: any) {
    if (language == 'el') {
      this.navbarDropdown = 'EL'
      this.iconUrl = `../../../assets/greece.png`


    } else if (language == 'en') {
      this.navbarDropdown = 'EN'
      this.iconUrl = `../../../assets/united-kingdom.png`



    }
    this.translate.use(language);


  }

  handleTabMenuWithSubMenus(e: any) {
    const targetElement: any = e.target;
    const subMenus = targetElement.children[1];

    //show the submenus by making the ul inside of targetElement visible, by adding the following styles
    if (subMenus) {
      subMenus.classList.add("activeFocus");
      subMenus.classList.add("show");
    }

    //check if you entered a new menu item. You enter a new menu item when the previous sibling of the target's parent is ul. If it's true,
    //then hide that ul by remove the styles you added before. This code catches the occassions that document.onkeydown doesn't, ie when the last subMenu
    //isn't the last menu item that got inserted in boxItems. This is happening if there is a subMenu with sub-subMenus above.
    if (targetElement.parentElement.previousSibling && targetElement.parentElement.previousSibling.tagName === "UL") {
      targetElement.parentElement.previousSibling.children[0].children[1].classList.remove("show");
    }
    

    if (subMenus) {
      //the menu that we are currently are, has subMenus. We want to put the LI element of each subMenu at boxItems
      const children = subMenus.childNodes;
      let items = this.boxItems; //current boxItems
      
      children.forEach((item: { children: any[]}) => {
        if (item.children && item.children[0] && item.children[0].tagName === "LI") {
          items.push(item.children[0]);
        }
      })
      this.boxItems = items;
    
    }
  }

  handleTabMenuWithoutSubMenus(event: any) {
    //we entered a new menu that does not have any subMenus. We only want to close the previous menu
    if (event.target.parentElement.previousElementSibling) {
      event.target.parentElement.previousElementSibling.children[0].children[1].classlist.remove("show");
    }
  }

  open(event: any) {
    event.target.nextElementSibling.classList.add("show")
  }

  close2(event: any) {
    if (event.target.children[1].classList[1]) {
      event.target.children[1].classList.remove("show")
    }
  }
  
}

<div class="container">
    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-12" style="display: flex;justify-content: space-between;align-items: end;">
            <div>
                <label class="label">{{article && article.attributes.categories?.data[0]?.attributes.name}}</label>
                <h2 class="borderLine">{{article && article.attributes.title}}</h2>
            </div>
           
            <div style="font-weight:bold;margin-left: auto;">{{article && article.attributes.published}}</div>
        </div>
        <!-- <div class="imgCont col-md-12" *ngIf="article && article.attributes.cover.data">
            <img [alt]="article.attributes.cover.data.attributes.alternativeText" [src]="cover" class="img-fluid" />
        </div> -->
       
        <div class="textCont col-md-12" [innerHTML]="body"> </div>
        <div *ngIf="article && article.attributes.files.data" style="margin-top: 3vh;">
            <p>Αρχεία:</p>
            <div *ngFor="let item of article.attributes.files.data">
                <a [href]="item.attributes.url">{{item.attributes.caption}}</a>
            </div>
        </div>

        <div *ngIf="article && article.attributes.photos.data" style="margin-top: 3vh;" class="container-fluid">
            <p>Φωτογραφίες:</p>
            <p-galleria [value]="article.attributes.photos.data" [(visible)]="displayCustom"
                [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions"
                [containerStyle]="{'max-width': '850px'}" [numVisible]="7" [circular]="true" [fullScreen]="true"
                [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.attributes.url" style="width: 100%; display: block;" />
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                        <img [src]="item.attributes.url" style="display: block;" class="imag-fluid" />
                    </div>
                </ng-template>
            </p-galleria>
            <div *ngIf="article.attributes.photos.data" style="display: flex;flex-direction: row;" class="row">
                <div *ngFor="let image of article.attributes.photos.data; let index = index;" class="col-md-2" style="margin-top: 2rem;"
                    key="index">
                    <img [src]="image.attributes.url" [alt]="image.attributes.name"
                        style="cursor: pointer;width: 10rem;" class="img-fluid" (click)="imageClick(index)" />
                </div>
            </div>
        </div>
        <div class="more col-md-12" *ngIf="moreArticles.length >0" style="margin-top: 3rem;">
            <h2 class="borderLine2" translate>moreArticles</h2>
            <div class="line"></div>
        </div>
        <div class="row" style="margin-top: 2rem;margin-bottom: 2rem;">
            <div class="col-md-6" *ngFor="let item of moreArticles">
                <div class="container-fluid">
                    <div class="row">
                        <div class="picture col-md-4">
                            <img class="img-fluid" [src]="item.cover">
                        </div>
                        <div class="content col-md-8">
                            <p style="align-self: flex-start;font-size: 0.9rem;color: gray;">{{item.attributes.published}}</p>
                            <h5 class="header" routerLink="/page/{{item.id}}">{{item.attributes.title}}</h5>
                            <p class="paragraph" [innerHTML]="item.attributes.short_description" ></p>
                            <a class='link' routerLink="/page/{{item.id}}" translate>more</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { PageComponent } from './page/page.component';
import { CategoryComponent } from './category/category.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { SearchComponent } from './search/search.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'page/:id',
    component: PageComponent,
  },
  {
    path: 'category/:id/:page',
    component: CategoryComponent,
  },
  {
    path: 'contact',
    component: ContactPageComponent,
  },
  {
    path: 'search',
    component: SearchComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',useHash: true,scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

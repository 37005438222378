<div class="header container" style="background: linear-gradient(to bottom, rgba(128, 139, 160, 0.15) 60%, #0F3C91 50%)">
    <div class="row" style="padding: 0.8rem;">
        <div class="col-3 col-md-3 imgg">
          <a routerLink="/"><img class="img-thumbnail" src="/assets/logo.png" style="width: 80px;"></a>
        </div>
        <div class="menu col-9 col-sm-9">
              <nav class="navbar navbar-light navbar-expand-lg">
                <div class="container-fluid">
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngFor="let item of navItems">
                      <li class="nav-item" *ngIf="item.children.length == 0" tabindex="0"
                      (keyup.Tab)="handleTabMenuWithoutSubMenus($event)">
                      <a *ngIf="item.attributes.url" class="nav-link "
                          routerLink={{item.attributes.url}}>{{item.attributes.name}}</a>
                      <a *ngIf="item.attributes.category.data" class="nav-link"
                          routerLink="/category/{{item.attributes.category.data.id}}/1">{{item.attributes.name}}</a>
                      <a *ngIf="item.attributes.page.data" class="nav-link"
                          routerLink="/page/{{item.attributes.page.data.id}}">{{item.attributes.name}}</a>
                      <a *ngIf="!item.attributes.page.data && !item.attributes.category.data && !item.attributes.url"
                          class="nav-link" >{{item.attributes.name}}</a>
                  </li>
                  <li class="nav-item dropdown" *ngIf="item.children.length != 0" (mouseleave)="close2($event)"
                (keyup.Tab)="handleTabMenuWithSubMenus($event)" tabindex="0">
                <a *ngIf="item.attributes.url" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (mouseover)="open($event)"
                    routerLink="{{item.attributes.ur}}">
                    {{item.attributes.name}}
                </a>
                <a *ngIf="item.attributes.category.data" class="nav-link dropdown-toggle" id="navbarDropdown"
                    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    (mouseover)="open($event)" routerLink="/category/{{item.attributes.category.data.id}}/1">
                    {{item.attributes.name}}
                </a>
                <a *ngIf="item.attributes.page.data" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (mouseover)="open($event)"
                    routerLink="/page/{{item.attributes.page.data.id}}">
                    {{item.attributes.name}}
                </a>
                <a *ngIf="!item.attributes.page.data && !item.attributes.category.data && !item.attributes.url"
                    class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" (mouseover)="open($event)">
                    {{item.attributes.name}}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span *ngFor="let subitem of item.children">
                        <li *ngIf="!subitem.children || subitem.children.length == 0" tabindex="0">
                            <a *ngIf="subitem.attributes.url && subitem.attributes.url !='/magazines'"
                                class="dropdown-item" href="{{subitem.attributes.url}}"
                                target="_blank">{{subitem.attributes.name}}</a>
                            <a *ngIf="subitem.attributes.url && subitem.attributes.url =='/magazines'"
                                class="dropdown-item" routerLink="/magazines/1">{{subitem.attributes.name}}</a>
                            <!-- <a *ngIf="subitem.attributes.url" class="dropdown-item"
                                routerLink="{{subitem.attributes.ur}}">{{subitem.attributes.name}}</a> -->
                            <a *ngIf="subitem.attributes.category.data" class="dropdown-item"
                                routerLink="/category/{{subitem.attributes.category.data.id}}/1">{{subitem.attributes.name}}</a>
                            <a *ngIf="subitem.attributes.page.data" class="dropdown-item"
                                routerLink="/page/{{subitem.attributes.page.data.id}}">{{subitem.attributes.name}}</a>
                            <a *ngIf="!subitem.attributes.page.data && !subitem.attributes.category.data && !subitem.attributes.url"
                                class="dropdown-item">{{subitem.attributes.name}}</a>
                        </li>
                        <li *ngIf="subitem.children && subitem.children.length > 0" class="nav-item dropdown"
                            (mouseleave)="close2($event)" tabindex="0">


                            <a *ngIf="subitem.attributes.url" class="dropdown-item dropdown-toggle"
                                href="{{subitem.attributes.ur}}" id="navbarDropdown1" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)">{{subitem.attributes.name}}
                            </a>
                            <a *ngIf="subitem.attributes.category.data" class="dropdown-item dropdown-toggle"
                                routerLink="/category/{{subitem.attributes.category.data.id}}/1" id="navbarDropdown1"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)">{{subitem.attributes.name}}
                            </a>
                            <a *ngIf="subitem.attributes.page.data" class="dropdown-item dropdown-toggle"
                                routerLink="/page/{{subitem.attributes.page.data.id}}" id="navbarDropdown1"
                                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)">{{subitem.attributes.name}}
                            </a>
                            <a *ngIf="!subitem.attributes.page.data && !subitem.attributes.category.data && !subitem.attributes.url"
                                class="dropdown-item dropdown-toggle" id="navbarDropdown1" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                (mouseover)="open($event)">{{subitem.attributes.name}}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                <span *ngFor="let subitem2 of subitem.children">
                                    <li tabindex="0">
                                        <a *ngIf="subitem2.attributes.url" class="dropdown-item"
                                            href="{{subitem2.attributes.url}}" target="_blank">{{subitem2.attributes.name}}</a>
                                        <a *ngIf="subitem2.attributes.category.data" class="dropdown-item"
                                            routerLink="/category/{{subitem2.attributes.category.data.id}}/1">{{subitem2.attributes.name}}</a>
                                        <a *ngIf="subitem2.attributes.page.data" class="dropdown-item"
                                            routerLink="/page/{{subitem2.attributes.page.data.id}}">{{subitem2.attributes.name}}</a>
                                        <a *ngIf="!subitem2.attributes.page.data && !subitem2.attributes.category.data && !subitem2.attributes.url"
                                            class="dropdown-item">{{subitem2.attributes.name}}</a>
                                    </li>
                                </span>

                            </ul>
                        </li>
                    </span>

                </ul>
            </li>
                    </ul>
                    <div class="dropdown">
                      <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" data-bs-toggle="dropdown" aria-expanded="false">
                        <img [src]="iconUrl" alt="arrow-icon" style="background:transparent; width:2vh;" />
                      {{navbarDropdown}}
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="switchLanguage('el', $event)"> <img src='../../../assets/greece.png' alt="greek-flag" 
                          style="background: transparent;width:2vh;" />EL</a>
                        <a class="dropdown-item"  (click)="switchLanguage('en', $event)"><img src='../../../assets/united-kingdom.png' alt="uk-flag" 
                          style="background: transparent;width:2vh;" />EN</a>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </nav>
              
        </div>
        <div class="col-sm-12">
          <ng-marquee [stopOnHover]="true" speed="drowsy">
            <mark class="item" *ngFor="let item of latestNews" routerLink="/page/{{item.id}}">({{item.attributes.published}}) - {{item.attributes.title}}</mark>
           
          </ng-marquee>
        </div>
       
    </div>
</div>